import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ended',
  templateUrl: './ended.component.html',
  styleUrls: ['./ended.component.scss']
})
export class EndedComponent implements OnInit {

  constructor(private util: UtilService, private activeRoute: ActivatedRoute) { }
  ngAfterViewInit(): void {
    this.util.updateUrlAfterLoad(`ended/${this.activeRoute.snapshot.params.meetingid}`);
  }
  ngOnInit(): void {
  }

}
