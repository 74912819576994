<div class="background-animation">
    <div class="wave"></div>
    <div class="wave"></div>
    <div class="wave"></div>
</div>

<div [hidden]="this.ready" class="meeting-title please-wait-div">
    <div>Please wait<app-loading-ellipses></app-loading-ellipses></div>
</div>
<div class="mb-5" id="meeting-title-container">
    <h2 [hidden]="!this.ready || (this.authorizedUser !== undefined && !this.authorizedUser) || inMeeting" class="meeting-title text-center py-2 border border-5 border-white">{{this.meetinginfo.title}}</h2>
</div>
<div class="selectmediacontainer" [hidden]="!this.ready || (this.authorizedUser !== undefined && !this.authorizedUser)">
    <div class="settingscontainerdiv">
        <div [hidden]="!this.authorizedUser" class="videodiv d-md-flex justify-content-center">
            <app-participant-video-setup-view #settingsVideo (videoSettingToggled)=videoSettingToggled() (audioSettingToggled)=audioSettingToggled() [inMeeting]="inMeeting"></app-participant-video-setup-view>
            <div class="mx-md-5 settingsdiv-container">       
                <div class="my-3 rounded" style="background-color: #F5F5F5;" *ngIf="displayMeetingSettingSection">
                    <div class="settingsdiv pb-0">
                        <div (click)="toggleSettingsDropDown(SettingsSection.Meeting)" class="settingswidth cursor-pointer border-bottom">
                            <h3>Meeting Settings</h3>
                            <span [hidden]="!meetingSettingSection?.nativeElement?.hidden">&#9660;</span><span [hidden]="meetingSettingSection?.nativeElement?.hidden"> &#9650;</span>
                        </div>
                        <div class="py-3" #meetingSetting>
                            <div *ngIf="showHostSetting" class="hostsettings settingsdiv pb-0">
                                <div class="settingswidth">
                                    <div class="settingsdiv-rows mb-1" *ngIf="this.meetinginfo.mode == this.util.meetingmode">
                                        <div class="settingsdiv-rows-left">
                                            <label for="allowParticipantsRecordCheckbox" class="mb-0">Allow Participants to record: </label>
                                        </div>
                                        <div class="settingsdiv-rows-right" style="width: unset;">
                                            <input type="checkbox" id="allowParticipantsRecordCheckbox" [(ngModel)]="allowNonHostsRecord"/> 
                                        </div>
                                    </div>
                                    <div class="settingsdiv-rows mb-1">
                                        <div class="settingsdiv-rows-left">
                                            <label for="recordMeetingCheckbox" class="mb-0">Record Meeting: </label>
                                        </div>
                                        <div class="settingsdiv-rows-right" style="width: unset;">
                                            <input type="checkbox" id="recordMeetingCheckbox" [(ngModel)]="recordMeeting" #recordMeetingCheckbox/> 
                                        </div>
                                    </div>
                                    <div class="settingsdiv-rows mb-1" *ngIf="this.meetinginfo.mode == this.util.meetingmode">
                                        <div class="settingsdiv-rows-left">
                                            <label for="muteParticipantsCheckbox" class="mb-0">Start Participants Muted: </label>
                                        </div>
                                        <div class="settingsdiv-rows-right" style="width: unset;">
                                            <input type="checkbox" id="muteParticipantsCheckbox" [(ngModel)]="startParticipantsMuted"/> 
                                        </div>
                                    </div>
                                    
                                    <div class="settingsdiv-rows mb-1">
                                        <div class="settingsdiv-rows-left">
                                            <label for="takeAttendanceCheckbox" class="mb-0">Take Attendance: </label>
                                        </div>
                                        <div class="settingsdiv-rows-right" style="width: unset;">
                                            <input type="checkbox" id="takeAttendanceCheckbox" [(ngModel)]="takeAttendance"/> 
                                        </div>
                                    </div>
                                    <div class="settingsdiv-rows mb-1" *ngIf="takeAttendance">
                                        <div class="settingsdiv-rows-left">
                                            <label for="attendanceInterval" class="mb-0">Attendance Interval: </label>
                                        </div>
                                        <div>
                                            <input type="number" min="5" id="attendanceInterval" [(ngModel)]="attendanceInterval" class="attendanceInterval frame p-2 mr-1"/> <span>(Minutes)</span>
                                        </div>
                                    </div>
                                    <div class="settingsdiv-rows mb-1">
                                        <div class="settingsdiv-rows-left">
                                            <label for="manuallyTakeAttendanceOnlyCheckbox" class="mb-0">Manual Attendance Only: </label>
                                        </div>
                                        <div class="settingsdiv-rows-right" style="width: unset;">
                                            <input type="checkbox" id="manuallyTakeAttendanceOnlyCheckbox" [(ngModel)]="manuallyTakeAttendanceOnly"/> 
                                        </div>
                                    </div>
                                    <!--<div class="settingsdiv-rows">
                                        <div class="settingsdiv-rows-left" style="width: 160px;">
                                            <p style="width: max-content"><label for="muteParticipantsCheckbox">Mode: </label></p>
                                        </div>
                                        <div class="settingsdiv-rows-right" style="width: unset;">
                                            <select [(ngModel)]="mode" [disabled]="greaterThan(this.meetinginfo.maxParticipants, this.meetinginfo.maxMeetingModeParticipants)" id="displayMode" class="p-2 rounded">
                                                <option value="meetingmode">Meeting Mode</option>
                                                <option value="focusmode">Lecture Mode</option>
                                            </select>
                                        </div>
                                    </div>-->
                                </div>
                            </div>
                            <div *ngIf="showRecordSetup" class="settingsdiv pt-0">
                                <div class="settingswidth">
                                    <div class="settingsdiv-rows mb-0">
                                        <div class="settingsdiv-rows-left">
                                            <label for="recordMeetingCheckbox" class="mb-0">Record Meeting: </label>
                                        </div>
                                        <div class="settingsdiv-rows-right" style="width: unset;">
                                            <input type="checkbox" id="recordMeetingCheckbox" [(ngModel)]="recordMeeting" #recordMeetingCheckbox/> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="showNotHostInfoWrap" class="nothostinfowrap">
                                <p class="settingswidth"><span class="font-weight-bold">Note: </span>Your webcam and microphone may be disabled until the host allows you to speak</p>
                            </div>
                            <div *ngIf="showAttendanceSetup" class="nothostinfowrap">
                                <p class="settingswidth"><i class="fa fa-info-circle"> </i> <span class="font-weight-bold"> Please Note: </span>Attendance will be taken in this meeting </p>
                            </div>
                        </div>
                    </div>                   
                </div>

                <div class="settingsdiv rounded" style="background-color: #F5F5F5;">
                    <div (click)="toggleSettingsDropDown(SettingsSection.CameraBackground)" class="settingswidth cursor-pointer border-bottom">
                        <h3>Camera Background Settings</h3>
                        <span [hidden]="!cameraBgSettingSection?.nativeElement?.hidden">&#9660;</span><span [hidden]="cameraBgSettingSection?.nativeElement?.hidden"> &#9650;</span>
                    </div>
                    <div class="py-3" #cameraBgSetting hidden>
                        <div class="d-flex justify-content-center camera-background-setup">
                            <div class="mx-3 camera-background-style rounded" (click)="selectBackgroundImage('-', true)" [ngClass]="selectedBackgroundImage == '-' ? 'border border-success': ''" >
                                <span class="d-flex justify-content-center align-items-center h-100"><img src="../../../assets/images/blur.svg" alt=""></span>
                            </div>
        
                            <div class="mx-3 camera-background-style rounded" (click)="triggerFileInput()">
                                <span class="d-flex justify-content-center align-items-center h-100"><i class="fas fa-plus"></i></span>
                            </div>
                            <div class="mx-3 camera-background-style rounded" (click)="selectBackgroundImage(null)" [ngClass]="selectedBackgroundImage == null ? 'border border-danger': ''">
                                <span class="d-flex justify-content-center align-items-center h-100"><i class="fas fa-ban"></i></span>                        
                            </div>
                        </div>
                        <div class="d-flex justify-content-center mt-3 overflow-auto">
                            <div *ngFor="let image of imageUrls">
                                <img [src]="image" [alt]="image" class="mx-3 rounded" [ngClass]="image == selectedBackgroundImage ? 'border border-success': ''" width="50" height="50" (click)="selectBackgroundImage(image)">
                            </div>
                        </div>
                        <input type="file" (change)="onFileSelected($event)" #selectFile accept="image/*" hidden>
                    </div>                                 
                </div>
                
                <div *ngIf="!inMeeting" class="joinbuttondiv mt-4">
                    <button (click)="enterMeeting()" #joinbutton [disabled]="this.joining || !this.ready" class="btn btn-primary"><span *ngIf="(!meetinginfo.started || meetinginfo.ended) && localuserinfo.host; else joinTemplate">Start</span><ng-template #joinTemplate><span>Join</span></ng-template></button>
                    <div *ngIf="this.joining" class="mt-1">
                        <span *ngIf="(!this.meetinginfo.started || this.meetinginfo.ended) && startingmeeting; else joiningTemplate">Starting Meeting. Please Wait<app-loading-ellipses></app-loading-ellipses></span></div>
                        <ng-template #joiningTemplate>
                            <span *ngIf="(this.meetinginfo.started && !this.meetinginfo.ended) || this.localuserinfo.host; else waitingForHostTemplate">Please wait while we connect you<app-loading-ellipses></app-loading-ellipses></span>
                            <ng-template #waitingForHostTemplate>
                                <span>Waiting for host to start the meeting<app-loading-ellipses></app-loading-ellipses></span>
                            </ng-template>
                        </ng-template>
                        
                </div>
            </div>
        </div>        
        <div *ngIf="this.authorizedUser" class="settingsdiv">
            <div class="device-settings-div settingswidth my-4">
                <h3 class="mb-3">Device Settings</h3>
                <div>
                    <div>
                        <div class="settingsdiv-rows">
                            <div class="settingsdiv-rows-left">
                                <label for="camera" class="mb-0">Camera: </label>
                            </div>
                            <div class="settingsdiv-rows-right">
                                <select [(ngModel)]="selectedCamera" (ngModelChange)="onCameraChange($event)" id="camera" class="frame p-2"> 
                                    <option *ngFor="let device of this.util.videoDevices; trackBy: identifyDevice" value="{{device.DeviceId}}">{{device.DeviceName}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="settingsdiv-rows">
                            <div class="settingsdiv-rows-left">
                                <label for="microphone" class="mb-0">Microphone: </label>
                            </div>
                            <div class="settingsdiv-rows-right">
                                <select [(ngModel)]="selectedMicrophone" (ngModelChange)="onMicrophoneChange($event)" id="microphone" class="frame p-2">
                                    <option *ngFor="let device of this.util.audioDevices; trackBy: identifyDevice" value="{{device.DeviceId}}">{{device.DeviceName}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>         
    </div>
</div>

<div *ngIf="this.authorizedUser !== undefined && !this.authorizedUser" class="modal d-flex justify-content-center" data-bs-backdrop="static" data-bs-keyboard="false"  tabindex="-1" id="usernamemodal">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Enter your name</h5>              
            </div>
            <div class="modal-body">
                <input type="text" id="username" [(ngModel)]="username" placeholder="Enter your name"/> 
            </div>
            <div class="modal-footer">
                <button (click)="publicuserContinue()" class="btn btn-primary offset-md-3"><span >OK</span></button>
            </div>
        </div>
    </div>
</div>       
