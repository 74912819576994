import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
//import { RecordingEventData, RecordingService } from 'src/app/services/recording.service';
import { UtilService } from '../../services/util.service';
import { MeetingHandlerService } from '../../services/meeting-handler.service';

@Component({
  selector: 'app-end',
  templateUrl: './end.component.html',
  styleUrls: ['./end.component.scss']
})
export class EndComponent implements OnInit, AfterViewInit {

  meetingid: string;
  jwt: string;
  cloudRecorder: boolean;
  constructor(private router: Router, private activatedroute: ActivatedRoute, private util: UtilService, private toastr: ToastrService, private meetingHandler: MeetingHandlerService) { }
  ngAfterViewInit(): void {
    this.util.updateUrlAfterLoad(`end/${this.meetingHandler.meetingid}`);
  }

  ngOnInit(): void {
    this.meetingid = this.activatedroute.snapshot.params.meetingid;
    this.jwt = this.activatedroute.snapshot.queryParams.auth;
    this.cloudRecorder = this.activatedroute.snapshot.queryParams.recorder == '1' || this.activatedroute.snapshot.queryParams.recorder == 'true';
    // this.recordingService.recordingEventSubject.subscribe((data: RecordingEventData) => {
    //   const {eventId, eventMessage} = data;
    //   switch(eventId){
    //     case 'recordingerror':
    //       this.toastr.error(eventMessage);
    //       break;
    //     case 'recordingprocessingerror':
    //       this.toastr.error(eventMessage);
    //       break;
    //     case 'recordingstoppedabruptly':
    //       this.toastr.warning(eventMessage);
    //       break;
    //     default:
    //   }
    // });
  }

  rejoin(){
    // if(this.util.mobile()){
    //   this.util.enterFullscreen();
    // }
    this.router.navigate([`/join/${this.meetingid}`], { queryParams: { auth: this.jwt } });
  }
}
